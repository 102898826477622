import React, { Component } from 'react'
import './loginToContinue.scss'
import queryString from 'query-string'
import { InputLabel } from '../Components'
import TextInput from '../Components/TextInput/TextInput'
import DatePicker from '../Components/DatePicker/DatePicker'
import * as API from '../API'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

class loginToContinue extends Component {
	constructor(props) {
		super(props)
		this.validateData = this.validateData.bind(this)
		this.isValid = this.isValid.bind(this)
		this.continueBtnHandler = this.continueBtnHandler.bind(this)
	}

	triesCounter = 0

	state = {
		mobile_tel: '',
		DOB: '',
		email: '',
		validator: [],
		isFormNotFound: false,
		token: '',
	}

	validateData() {
		let notValid = []
		const st = this.state
		!st.mobile_tel && notValid.push({ field: 'mobile_tel', massage: 'required' })
		!st.DOB && notValid.push({ field: 'DOB', massage: 'required' })
		!st.email
			? notValid.push({ field: 'email', massage: 'required' })
			: !st.email.match(/[\w-]+@([\w-]+\.)+[\w-]+/) && notValid.push({ field: 'email', massage: 'Please enter a correct email' })
		return notValid
	}
	continueBtnHandler() {
		const fields = this.state
		const dataToValidate = this.validateData()
		if (dataToValidate.length !== 0) {
			this.setState({ validator: [...dataToValidate] })
			return
		}
		this.setState({ validator: [] })

		API.getFormViaForm(fields.mobile_tel, fields.DOB, fields.email, fields.token)
			.then((response) => {
				if (this.state.token !== '') {
					this.props.history.push({
						pathname: `/home/continue/form`,
						state: { form: response.data.data, token: this.state.token },
					})
				}
			})
			.catch((error) => {
				if (this.triesCounter === 5) {
					this.props.history.push(`/home`) // after 5 tries an applicant is redirected to empty form
				} else this.setState({ isFormNotFound: true })
				++this.triesCounter
			})
	}

	isValid(field) {
		return !this.state.validator.some((notvalid) => notvalid.field === field)
	}

	componentDidMount() {
		const query = queryString.parse(this.props.location.search)
		this.setState({ token: query.token })
		console.log(this.token)
	}
	render() {
		const { mobile_tel, DOB, email, validator, isFormNotFound } = this.state
		const { isMobile } = this.props
		const { continueBtnHandler, isValid } = this
		return (
			<div className={'login-container'}>
				<div className={'login-title'}>Enter user data</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', ...(isMobile ? { flexDirection: 'column' } : {}) }}>
					<div>
						<InputLabel text='Mobile tel' />
						<TextInput
							width={isMobile ? '100%' : '170px'}
							style={{ color: '#9B9B9B', fontWeight: 'bold' }}
							value={mobile_tel}
							changeHandler={(value) => {
								this.setState({ mobile_tel: value })
							}}
							isDisabled={false}
							restriction={'digits'}
							error={
								!isValid('mobile_tel') &&
								validator.find((notValid) => {
									return notValid.field === 'mobile_tel'
								}).massage
							}
						/>
					</div>
					<div>
						<InputLabel text='Date of birth' />
						<DatePicker
							width={isMobile ? '100%' : '155px'}
							inputStyle={{ height: 35 }}
							value={DOB}
							changeHandler={(DOB) => this.setState({ DOB })}
							isDisabled={false}
							error={
								!isValid('DOB') &&
								validator.find((notValid) => {
									return notValid.field === 'DOB'
								}).massage
							}
						/>
					</div>
				</div>
				<InputLabel style={{ marginRight: 5 }} text='Email' />
				<TextInput
					width={isMobile ? '100%' : '335px'}
					style={{ color: '#9B9B9B', fontWeight: 'bold' }}
					value={email}
					changeHandler={(value) => {
						this.setState({ email: value })
					}}
					isDisabled={false}
					error={
						!isValid('email') &&
						validator.find((notValid) => {
							return notValid.field === 'email'
						}).massage
					}
				/>
				{isFormNotFound && <div className={'not-found'}>The application form with given data has not been found</div>}
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<button onClick={continueBtnHandler} className='continue-btn'>
						Continue
					</button>
					<div>
						<p>
							Note: <span style={{ color: '#9B9B9B' }}>You have only 5 attempts after which you will be blocked</span>
						</p>
					</div>
				</div>
			</div>
		)
	}
}

loginToContinue.propTypes = {
	isMobile: PropTypes.bool,
}

loginToContinue.defaultProps = {
	isMobile: false,
}

export default withRouter(loginToContinue)
