import React, { useEffect, useState } from 'react'
import styles from './style.module.scss'
import { useToggle } from '@Root/hooks'
import { Preloader } from '@Root/Components'
import { PersonalInformation } from './PersonalInformation'
import { InternationalApplicants } from './InternationalApplicant'
import { Programme } from './Programme'
import { EducationAndEmployment } from './EducationAndEmployment'
import { MinisterialDetails } from '@Root/Components/Forms/Application/MinisterialDetails'
import { UserStory } from '@Root/Components/Forms/Application/UserStory'
import { UserCircumstances } from '@Root/Components/Forms/Application/UserCircumstances'
import { ConnectionToUs } from '@Root/Components/Forms/Application/ConnectionToUs'
import { References } from '@Root/Components/Forms/Application/References'
import { Declaration } from '@Root/Components/Forms/Application/Declaration'
import { regExps } from '@Root/helpers'
import Popup from '@Root/Components/PopUps/popUp'
import { useHistory, useLocation } from 'react-router'
import { dioceseRequest } from '@Root/API'
import moment from 'moment'
const today = new Date()

export const ApplicationForm = ({ onSubmit, onSave, options, isNew, initialValue, token, isMobile }) => {
	const history = useHistory()
	const location = useLocation()
	const [errors, setError] = useState({})
	const [modalContent, setModalContent] = useState('')
	const [data, setData] = useState({
		title: '',
		firstname: '',
		surname: '',
		address: '',
		city: '',
		emergency_contact_name: '',
		emergency_contact_number: '',
		emergency_contact_relationship: '',
		home_church_address: '',
		postcode: '',
		DOB: '',
		nationality_type_id: null,
		email: '',
		mobile_phone: '',
		sex_id: null,
		country_birth: null,
		country_of_permanent: null,
		passport_number: '',
		is_english_first_language: true,
		taken_IELTS_exam: true,
		is_scope: '',
		study_program: [],
		sectionThreeFields: [],
		full_time: 1,
		context_based_training: true,
		educational_qualifications: [{ Institution: '', Subject: '', Award: '', Result: '', Date: '' }],
		professional_qualifications: [{ Institution: '', Subject: '', Award: '', Result: '', Date: '' }],
		recent_employment_history: [{ Employer: '', Role: '', 'Dates from/to': '', 'FT/PT': '' }],
		name_of_current_church: '',
		sending_diocese: null,
		name_of_your_DDO: '',
		date_of_last_meeting_with_DDO: '',
		email_contact_for_your_DDO: '',
		phone_contact_fo_your_DDO: '',
		progress_stage: null,
		progress_stage_date: '',
		story: '',
		british_visa_status: 1,
		church_of_england: 1,
		source_of_funding: 1,
		accomodation_options: 1,
		outcome_of_BAP: null,
		marital_status: 1,
		first_hear_about_trinity: 1,
		attracts_you_to_trinity: 1,
		communicate_with_you_in_the_future: 1,
		name_of_your_spouse: '',
		surname_of_your_spouse: '',
		names_and_ages_of_your_children: '',
		additional_needs: false,
		additional_needs_description: '',
		learning_difficulty: false,
		learning_difficulty_description: '',
		references_1_name: '',
		references_1_relationship_to_you: '',
		references_1_email: '',
		references_2_name: '',
		references_2_relationship_to_you: '',
		references_2_email: '',
		references_2_description: '',
		accrediting_body: false,
		Date: '',
		captchaCode: '',
		validator: [],
		isDataLoaded: false,
		showPopup: false,
		text: '',
		isEmailExists: false,
		isWaiting: false,
		applied_before: {
			value: false,
			note_details: '',
		},
		previous_surname: '',
		outcome_pioneer_minister: null,
	})
	const [isEmailExist, toggleEmailExist] = useToggle()
	const [isLoading, toggleLoading] = useToggle()
	useEffect(() => {
		if (initialValue) {
			const { country_birth, country_of_permanent, study_program, educational_qualifications, professional_qualifications, recent_employment_history } =
				initialValue
			setData({
				...initialValue,
				token,
				country_birth: country_birth.id,
				country_of_permanent: country_of_permanent.id,
				study_program: Object.keys(study_program).map((key) => Number(key)),
				educational_qualifications: educational_qualifications.map(({ Institution, Subject, Award, Result, Date, id }) => {
					return {
						Institution: Institution,
						Subject: Subject,
						Award: Award,
						Result: Result,
						Date: Date,
						id: id,
					}
				}),
				professional_qualifications: professional_qualifications.map(({ Institution, Subject, Award, Result, Date, id }) => {
					return {
						Institution: Institution,
						Subject: Subject,
						Award: Award,
						Result: Result,
						Date: Date,
						id: id,
					}
				}),
				recent_employment_history: recent_employment_history.map((empField) => {
					return {
						Employer: empField['Employer'],
						Role: empField['Role'],
						Dates_from_to: empField['Dates from/to'],
						FT_PT: empField['FT/PT'],
						id: empField.id,
					}
				}),
			})
		}
	}, [initialValue, token])

	const onCloseModal = () => {
		if (!isEmailExist) {
			history.push(`/home/formSubmission`)
			return
		}
		setModalContent('')
	}

	const onAddError = (field, error) => {
		setError((prevState) => ({ ...prevState, [field]: error }))
	}

	const scrollTo = () => {
		const scrollTo = document.getElementById('scrollHere')
		if (scrollTo) {
			window.scrollTo(0, scrollTo.offsetTop - 20)
		}
	}

	const validateForm = () => {
		let isValid = true
		const {
			accrediting_body,
			Date,
			firstname,
			surname,
			DOB,
			sex_id,
			email,
			mobile_phone,
			study_program,
			captchaCode,
			email_contact_for_your_DDO,
			references_1_email,
			references_2_email,
			address,
			church_of_england,
		} = data

		if (!(Date && accrediting_body)) {
			onAddError('accrediting_body', 'required')
			isValid = false
		} else {
			onAddError('accrediting_body', null)
		}
		if (!firstname) {
			onAddError('firstname', 'required')
			isValid = false
		} else {
			onAddError('firstname', null)
		}

		if (!surname) {
			onAddError('surname', 'required')
			isValid = false
		} else {
			onAddError('surname', null)
		}
		if (!address) {
			onAddError('address', 'required')
			isValid = false
		} else {
			onAddError('address', null)
		}
		if (!DOB) {
			onAddError('DOB', 'required')
			isValid = false
		} else {
			if (DOB === moment(today).format('DD-MM-YYYY')) {
				onAddError('DOB', 'The DOB must be a date before today')
				isValid = false
			} else {
				onAddError('DOB', null)
			}
		}
		if (!email) {
			onAddError('email', 'required')
			isValid = false
		}
		if (email && !email.match(regExps.email)) {
			onAddError('email', 'Please enter a correct email')
			isValid = false
		} else if (email) {
			onAddError('email', null)
		}

		if (!mobile_phone) {
			onAddError('mobile_phone', 'required')
			isValid = false
		} else {
			onAddError('mobile_phone', null)
		}
		if (study_program?.length === 0) {
			onAddError('study_program', 'required')
			isValid = false
		} else {
			onAddError('study_program', null)
		}
		if (!captchaCode) {
			onAddError('captchaCode', 'required')
			isValid = false
		} else {
			onAddError('captchaCode', null)
		}

		if (!email_contact_for_your_DDO && church_of_england === 1) {
			onAddError('email_contact_for_your_DDO', 'required')
			isValid = false
		} else {
			if (email_contact_for_your_DDO && !email_contact_for_your_DDO?.match(regExps.email)) {
				onAddError('email_contact_for_your_DDO', 'Please enter a correct email')
				isValid = false
			} else {
				onAddError('email_contact_for_your_DDO', null)
			}
		}

		if (references_1_email && !references_1_email?.match(regExps.email)) {
			onAddError('references_1_email', 'Please enter a correct email')
			isValid = false
		}
		if (!sex_id) {
			onAddError('sex_id', 'required')
			isValid = false
		} else {
			onAddError('sex_id', null)
		}
		if (references_2_email && !references_2_email?.match(regExps.email)) {
			onAddError('references_2_email', 'Please enter a correct email')
			isValid = false
		} else {
			onAddError('references_2_email', null)
		}
		return isValid
	}

	const onChange = (field) => (value) => {
		setData((prevState) => ({ ...prevState, [field]: value }))
	}

	const onChangeFormTable = (field) => (rowIndex, fieldName, value, rows) => {
		const newRows = rows.map((row, index) => (index === rowIndex ? { ...row, [fieldName]: value } : row))
		setData((prevState) => ({ ...prevState, [field]: newRows }))
	}

	const onAddFormTableRow = (field) => (rows) => {
		const newRow = Object.keys(rows[0])
			.filter((key) => key !== 'id')
			.reduce((acc, curr) => {
				return { ...acc, [curr]: '' }
			}, {})

		setData((prevState) => ({ ...prevState, [field]: [...rows, newRow] }))
	}

	const modifiedData = () => {
		const { programmes } = options
		const { study_program } = data
		const newProgrammes = programmes.reduce((acc, { value }) => {
			if (value !== 'category') {
				if (study_program.some((slectedIndex) => slectedIndex === value)) {
					return { ...acc, [value]: 1 }
				} else {
					return { ...acc, [value]: 0 }
				}
			}
			return acc
		}, {})
		return {
			...data,
			study_program: newProgrammes,
		}
	}

	const onFindDiocese = async (name) => {
		if (name) {
			const res = await dioceseRequest(1, 50, name)
			return res?.data?.data.map(({ id, name }) => ({ value: id, label: name }))
		}
	}

	const onFindDioceseById = async (id) => {
		if (id) {
			const res = await dioceseRequest(1, 50, '', id)
			setData((prevState) => ({ ...prevState, sending_diocese: res.data?.data?.[0]?.id }))
			return
		}
		setData((prevState) => ({ ...prevState, sending_diocese: null }))
	}

	const fetchLabelHandler = async (id) => {
		const res = await dioceseRequest(1, 50, '', id)
		return res.data?.data?.[0]?.name
	}

	const onSaveForm = () => {
		if (!validateForm()) {
			scrollTo()
			return
		}
		const data = modifiedData()
		setError({})
		toggleLoading()
		onSave(data)
			.then(() => {
				setModalContent('Your application was saved successfully. We have sent you a confirmation email (please check your junk/spam folder).')
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toggleEmailExist()
					setModalContent('The form with given email already exists.')
				}
			})
			.finally(() => toggleLoading())
	}

	const onSubmitForm = () => {
		if (!validateForm()) {
			scrollTo()
			return
		}
		const data = modifiedData()
		setError({})
		toggleLoading()
		onSubmit(data)
			.then(() => {
				setModalContent('Your application was accepted successfully.')
			})
			.catch((error) => {
				if (error.response.status === 403) {
					toggleEmailExist()
					setModalContent('The form with given email already exists.')
				}
			})
			.finally(() => toggleLoading())
	}

	const { accrediting_body, Date } = data
	return (
		<div className={styles.form}>
			<div className={styles.container}>
				<div className={styles.header}>
					<h3 className={styles.title}>Complete a Student Application</h3>
					<div className={styles.content}>
						<strong>
							Please complete the form below. You can do so in more than one session by using the 'Save' button beneath the form. When you are ready to submit
							your completed application, please use the 'Finish and Submit' button.
						</strong>
						<p>Sections marked * are required.</p>
						<strong>We look forward to receiving your application!</strong>
					</div>
				</div>
				<div>
					<div className={styles.wrapper}>
						<div className={styles.column}>
							<PersonalInformation onChange={onChange} errors={errors} data={data} options={options} isMobile={isMobile} />
						</div>
						<div className={styles.column}>
							<InternationalApplicants onChange={onChange} errors={errors} data={data} options={options} isMobile={isMobile} />
						</div>
					</div>
					<div className={styles.wrapper}>
						<div className={styles.column}>
							<Programme onChange={onChange} errors={errors} data={data} options={options} isMobile={isMobile} />
						</div>{' '}
						<div className={styles.column}>
							<EducationAndEmployment onChange={onChangeFormTable} onChangeField={onChange} onAddRow={onAddFormTableRow} errors={errors} data={data} />
						</div>
					</div>
					<div className={styles.wrapper}>
						<div className={styles.column}>
							<MinisterialDetails
								onChange={onChange}
								errors={errors}
								data={data}
								isMobile={isMobile}
								options={options}
								onFindDiocese={onFindDiocese}
								onFindDioceseById={onFindDioceseById}
								fetchLabelHandler={fetchLabelHandler}
							/>
						</div>
						<div className={styles.column}>
							<UserStory onChange={onChange} errors={errors} data={data} isMobile={isMobile} />
						</div>
					</div>
					<div className={styles.wrapper}>
						<div className={styles.column}>
							<UserCircumstances onChange={onChange} errors={errors} data={data} isMobile={isMobile} />
						</div>
						<div className={styles.column}>
							<ConnectionToUs onChange={onChange} errors={errors} data={data} isMobile={isMobile} />
						</div>
					</div>
					<div className={styles.wrapper_low}>
						<div className={styles.column}>
							<References onChange={onChange} errors={errors} data={data} isMobile={isMobile} />
						</div>
						<div className={styles.column}>
							<Declaration onChange={onChange} errors={errors} data={data} isMobile={isMobile} />
						</div>
					</div>
					<div className={styles.actions}>
						<button
							style={{ marginRight: 0 }}
							onClick={onSaveForm}
							className={`${styles.btn} ${styles.btn_save} ${!(accrediting_body && Date) || isLoading ? styles.disabled_btn : ''}`}
						>
							Save
						</button>
						<span style={{ color: '#9B9B9B' }}>Or</span>
						<button
							style={{ marginRight: 0 }}
							onClick={onSubmitForm}
							className={`${styles.btn} ${styles.btn_finish} ${!(accrediting_body && Date) || isLoading ? styles.disabled_btn : ''}`}
						>
							Finish and Submit
						</button>
					</div>
				</div>
			</div>
			{isLoading && <Preloader />}
			{modalContent && <Popup text={modalContent} closePopup={onCloseModal} />}
		</div>
	)
}
