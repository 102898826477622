import React, { Component } from 'react'
import './studentFormSubmission.scss'
import { InputLabel } from '../Components'
import TextInput from '../Components/TextInput/TextInput'
import * as API from '../API'
import DatePicker from '../Components/DatePicker/DatePicker'
import Popup from '../Components/PopUps/popUp'
class studentFormSubmission extends Component {
	constructor(props) {
		super(props)
		this.submitBtnHandler = this.submitBtnHandler.bind(this)
		this.RequestBtnHandler = this.RequestBtnHandler.bind(this)
		this.validateData = this.validateData.bind(this)
		this.isValid = this.isValid.bind(this)

		this.myRef = React.createRef()
	}
	state = { showTokenInput: false, token: '', tokenError: '', mob_tel: '', DOB: '', email: '', validator: [], showPopup: false, text: '', formContent: null }
	submitBtnHandler() {
		const { history, location } = this.props
		if (this.state.token === '') {
			this.setState({ tokenError: 'Please, input a token' })
			return
		}
		API.getFormViaToken(this.state.token)
			.then((response) => {
				history.push({
					pathname: `/home/continue/form`,
					state: { form: response.data.data, token: this.state.token },
				})
			})
			.catch((error) => {
				console.log(error)
				this.setState({ tokenError: 'The given token is invalid' })
			})
	}

	isValid(field) {
		return !this.state.validator.some((notvalid) => notvalid.field === field)
	}

	validateData() {
		let notValid = []
		const st = this.state
		!st.mob_tel && notValid.push({ field: 'mobile_tel', massage: 'required' })
		!st.DOB && notValid.push({ field: 'DOB', massage: 'required' })
		!st.email
			? notValid.push({ field: 'email', massage: 'required' })
			: !st.email.match(/[\w-]+@([\w-]+\.)+[\w-]+/) && notValid.push({ field: 'email', massage: 'Please enter a correct email' })
		return notValid
	}

	RequestBtnHandler() {
		const dataToValidate = this.validateData()
		if (dataToValidate.length !== 0) {
			this.setState({ validator: [...dataToValidate] })
			return
		}
		this.setState({ validator: [] })
		API.updateToken({ email: this.state.email, mobile_phone: this.state.mob_tel, date_of_birth: this.state.DOB })
			.then((response) => {
				this.setState({ showPopup: true })
				this.setState({ text: 'An email with login details has been sent.' })
			})
			.catch((error) => {
				console.log(error)
				if (error.response.status === 400) {
					this.setState({ showPopup: true })
					this.setState({ text: 'The form with given data does not exist.' })
				}
			})
	}
	componentWillMount() {
		API.getFormSubmissionContent().then((response) => {
			this.setState({ formContent: response.data.html?.header })
		})
	}
	componentDidMount() {
		if (this.myRef) {
			window.scrollTo(0, this.myRef.offsetTop)
		}
	}
	onFormSubmit(location) {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
		this.props.history.push(`/home/formSubmission/studentForm`)
	}
	render() {
		const { submitBtnHandler, RequestBtnHandler, isValid } = this
		const { showTokenInput, token, tokenError, mob_tel, DOB, email, validator, showPopup, text } = this.state
		const { location } = this.props

		return (
			<div ref={this.myRef} className='formSubmission'>
				<div className='formSubmission-wrapper'>
					<div dangerouslySetInnerHTML={{ __html: this.state.formContent }}></div>
					<div className='submission-buttons' style={{ marginBottom: 20 }}>
						<div style={{ display: 'flex' }} className='submission-buttons_wrapper'>
							<button onClick={() => this.onFormSubmit(location)}>START NEW APPLICATION</button>
							<button
								onClick={() => {
									this.setState({ showTokenInput: !showTokenInput })
								}}
							>
								Complete an existing app
							</button>
						</div>
						{showTokenInput && (
							<div className='submission-token'>
								<div className='submission-title'>
									If you wish to resume an existing application form, please enter the token that you were emailed after you saved your form
								</div>
								<InputLabel text='Your token' />
								<TextInput
									width='100%'
									style={{ color: '#9B9B9B', fontWeight: 'bold' }}
									value={token}
									changeHandler={(token) => this.setState({ token })}
									isDisabled={false}
									error={tokenError !== '' && tokenError}
								/>
								<button onClick={submitBtnHandler}>Submit</button>

								<div className='formBirth-wrapper'>
									<div className='submission-title'>
										If you have forgotten or lost the token that you need to complete an existing application from, please fill in the form below to recelve a
										new one. The token will be sent to your email address - if you do not recelve an email please also check your junk email/spam folder, or
										contact admissions@trinitycollegebristol.ac.uk
									</div>
									<InputLabel text='Mobile tel' />
									<TextInput
										style={{ width: '100%', color: '#9B9B9B', fontWeight: 'bold' }}
										value={mob_tel}
										changeHandler={(value) => {
											this.setState({ mob_tel: value })
										}}
										isDisabled={false}
										restriction={'digits'}
										error={
											!isValid('mobile_tel') &&
											validator.find((notValid) => {
												return notValid.field === 'mobile_tel'
											}).massage
										}
									/>

									<InputLabel text='Date of birth' />
									<DatePicker
										width='100%'
										inputStyle={{ height: 35 }}
										value={DOB}
										changeHandler={(DOB) => this.setState({ DOB })}
										isDisabled={false}
										error={
											!isValid('DOB') &&
											validator.find((notValid) => {
												return notValid.field === 'DOB'
											}).massage
										}
									/>
									<InputLabel text='Email' />

									<TextInput
										style={{ width: '100%', color: '#9B9B9B', fontWeight: 'bold' }}
										value={email}
										changeHandler={(value) => {
											this.setState({ email: value })
										}}
										isDisabled={false}
										error={
											!isValid('email') &&
											validator.find((notValid) => {
												return notValid.field === 'email'
											}).massage
										}
									/>
									<div style={{ marginTop: 15, marginBottom: 20 }} className={'submission-buttons'}>
										<button style={{ width: 115 }} onClick={RequestBtnHandler} className='continue-btn'>
											Request
										</button>
									</div>
									{showPopup ? (
										<Popup
											txtWidth={300}
											text={text}
											closePopup={() => {
												this.setState({ showPopup: !this.state.showPopup })
											}}
										/>
									) : null}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}
}

export default studentFormSubmission
