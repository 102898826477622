import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { booleanOptions, britishVisaOptions } from '@Root/configs'

export const InternationalApplicants = ({ onChange, data, isMobile, options }) => {
	const { british_visa_status, passport_number, taken_IELTS_exam, is_scope, country_birth, country_of_permanent, is_english_first_language } = data
	return (
		<div className={styles.section}>
			<SectionTitle>2. International Applicants</SectionTitle>
			<div className={styles.fields}>
				<p className={styles.note}>
					Note: <span style={{ color: '#9B9B9B' }}>If you are a British citizen, please move straight on to section 3</span>
				</p>
				<Field
					label={'Country of birth'}
					options={options.countries}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					optionsStyle={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					value={country_birth}
					onChange={onChange('country_birth')}
					isInline
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Country of permanent residence'}
					options={options.countries}
					field={'select'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					optionsStyle={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '260px' }}
					value={country_of_permanent}
					onChange={onChange('country_of_permanent')}
					isInline
					fieldClass={'long'}
				/>
				<Field
					label={'British visa status'}
					options={britishVisaOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={british_visa_status}
					onChange={onChange('british_visa_status')}
					isInline
					fieldClass={'long'}
				/>
				<Field
					label={'Passport number'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '240px' }}
					value={passport_number}
					onChange={onChange('passport_number')}
					maxLength={100}
					fieldClass={'long'}
				/>
				<Field
					label={'Is English your first language?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={is_english_first_language ? 1 : 2}
					onChange={(value) => onChange('is_english_first_language')(value === 1)}
					isInline
					fieldClass={'long'}
				/>
				<Field
					label={'If not, have you taken an IELTS exam?'}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '240px' }}
					options={booleanOptions}
					value={taken_IELTS_exam ? 1 : 2}
					onChange={(value) => onChange('taken_IELTS_exam')(value === 1)}
					fieldClass={'long'}
					isInline
				/>{' '}
				<Field
					label={'If yes, what was your score?'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '240px' }}
					value={is_scope}
					onChange={onChange('is_scope')}
					maxLength={100}
					isRequired
					fieldClass={'long'}
				/>
				<p style={{ color: '#9B9B9B' }}>
					*Please note that undergraduate applications whose first language is not English are required to have a minimum overall score of 6.5 on the IELTS
					exam, and for postgraduate applications the minimum score is 7.5
				</p>
			</div>
		</div>
	)
}
