import React from 'react'
import { ApplicationForm } from '@Root/Components'
import * as API from '@Root/API'

export const CreateApplication = ({ options, isMobile }) => {
	const onSubmit = (payload) => {
		return API.finishStudenFormRequest(payload)
	}

	const onSave = (payload) => {
		return API.saveStudenFormRequest(payload)
	}

	return <ApplicationForm onSubmit={onSubmit} onSave={onSave} options={options} isNew isMobile={isMobile} />
}
