export const titlesOptions = [
	{ value: 1, label: 'Mr' },
	{ value: 2, label: 'Mrs' },
	{ value: 3, label: 'Miss' },
	{ value: 4, label: 'Rev' },
	{ value: 5, label: 'Dr' },
	{ value: 6, label: 'Other' },
]

export const churchOfEnglandOptions = [
	{ value: 1, label: 'Ordinand' },
	{ value: 2, label: 'Independent' },
]

export const timesOptions = [
	{ value: 1, label: 'FT' },
	{ value: 2, label: 'PT' },
]

export const booleanOptions = [
	{ value: 1, label: 'Yes' },
	{ value: 2, label: 'No' },
]

export const accommodationOptions = [
	{ value: 1, label: 'Renting privately' },
	{ value: 2, label: 'Renting a college property' },
	{ value: 3, label: 'Buying privately' },
	{ value: 4, label: 'Living onsite in halls of residence full time (currenly only single rooms are avaliable)' },
	{ value: 5, label: 'Living onsite in halls of residence part-time, commuting home at weekends' },
	{ value: 6, label: 'I have no accommodation needs' },
]

export const heardFrom = [
	{ value: 1, label: 'Former student' },
	{ value: 2, label: 'Current student' },
	{ value: 3, label: 'Facebook' },
	{ value: 4, label: 'Twitter' },
	{ value: 5, label: 'Google' },
	{ value: 6, label: 'Church' },
]

export const attractionOptions = [
	{ value: 1, label: 'Academic programme' },
	{ value: 2, label: 'Vision' },
	{ value: 3, label: 'Faculty' },
	{ value: 4, label: 'Community' },
	{ value: 5, label: 'Worship style' },
	{ value: 6, label: 'Other' },
	{ value: 7, label: 'Location' },
	{ value: 8, label: 'Theological position of the college' },
]

export const communicationOptions = [
	{ value: 1, label: 'Post' },
	{ value: 2, label: 'Email' },
	{ value: 3, label: 'Phone' },
	{ value: 4, label: 'Social media' },
]

export const educationQualificationOptions = ['Institution', 'Subject', 'Award', 'Result(s)', 'Date']

export const professionalQualificationsOptions = ['Institution', 'Subject', 'Award', 'Result(s)', 'Date']

export const recentEmploymentOptions = ['Employer', 'Role', 'Dates from/to', 'FT/PT']

export const britishVisaOptions = [
	{ value: 1, label: 'Not needed' },
	{ value: 2, label: 'Granted' },
	{ value: 3, label: 'Required' },
]
export const progressThroughNationalDiscernmentProcessOptions = [
	{ value: 1, label: 'Stage 1 complete' },
	{ value: 2, label: 'Stage 1 booked' },
	{ value: 3, label: 'Stage 2 booked' },
]

export const outcomeBapOptions = [
	{ value: 1, label: 'Not recommended' },
	{ value: 2, label: 'Not yet known' },
	{ value: 3, label: 'Recommended' },
	{ value: 4, label: 'No date fixed at this stage' },
]

export const sourceFundingOptions = [
	{ value: 1, label: 'Personal finance' },
	{ value: 2, label: 'Supporting church' },
	{ value: 3, label: 'Student loan' },
	{ value: 4, label: 'CofE funding' },
	{ value: 5, label: 'Other' },
]

export const maritalCircumstances = [
	{ value: 1, label: 'Single' },
	{ value: 2, label: 'Married' },
	{ value: 3, label: 'Widowed' },
	{ value: 4, label: 'Separated' },
	{ value: 5, label: 'Divorced' },
	{ value: 6, label: 'Other' },
]
