import React, { Component } from "react"
import "./MultiSelectItems.scss"
class MultiSelectItems extends Component{
    render() {
        const {checked, option, onClick} = this.props;
        return(
            <div>
                {
                    option.value === "category" ? (
                        <span  className="category">
                         {option.label}
                        </span>
                    ) : (
            <div className="programme">
                <input
                    type="checkbox"
                    onChange={onClick}
                    checked={checked}
                    tabIndex="-1"
                />
            <span>
                {option.label}
            </span>
        </div>
                    )
                }
            </div>
            )
    }
}

export default MultiSelectItems