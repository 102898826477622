import React, { Component } from 'react'
import './homePage.scss'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import studentFormSubmission from '../StudentFormSubmission/studentFormSubmission'
import LoginToContinue from '../LoginToContinue/loginToContinue'
import { ApplicationPage } from '@Root/pages'
import {
	BannerFacebookIcon,
	BannerLogoIcon,
	BannerTwitterIcon,
	BlogIcon,
	ContactIcon,
	DSAImage,
	FacebookIcon,
	ReviewGraphic,
	TrinityIcon,
	TwitterIcon,
	UniAberdeen,
	UniDurham,
} from '@Root/assets/icons'
import './homePage.scss'

class homePage extends Component {
	state = {
		isMobile: false,
		menuIsShown: true,
		openedSubmenu: null,
	}

	containerRef = React.createRef()

	collegeTitles = (collegeId) => {
		return {
			trinitycollegebristol: ' Trinity College, Bristol - Taught Programmes Application Form',
			theofedcam: 'Cambridge Federation',
			stmellitus: 'St Mellitus College',
		}[collegeId]
	}

	setIsMobile = () => {
		this.setState({
			isMobile: this.containerRef.current.offsetWidth < 615,
		})
	}

	componentDidMount() {
		this.setIsMobile()
		window.addEventListener('resize', this.setIsMobile)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.isMobile === true && this.state.isMobile === false) {
			this.setState({ menuIsShown: true, openedSubmenu: null })
		}
		if (prevState.isMobile === false && this.state.isMobile === true) {
			this.setState({ menuIsShown: false, openedSubmenu: null })
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.setIsMobile)
	}

	render() {
		const { isMobile, menuIsShown, openedSubmenu } = this.state
		return (
			<div ref={this.containerRef} className={'HomePage'}>
				<div className='header'>
					<div className='header-container'>
						<div className='header-upper'>
							{!isMobile && (
								<div className='share-icons'>
									<a className='twitter-icon' target='_blank' href='http://twitter.com/trinity_bristol'>
										<img src={BannerTwitterIcon} alt='' />
									</a>
									<a className='facebook-icon' target='_blank' href='https://www.facebook.com/trinitycollegebristol'>
										<img src={BannerFacebookIcon} alt='' />
									</a>
									<a className='blog-icon' href='https://www.trinitycollegebristol.ac.uk'>
										<img src={BlogIcon} alt='' />
									</a>
								</div>
							)}

							<a href='https://www.trinitycollegebristol.ac.uk/'>
								<img src={BannerLogoIcon} alt='' />
							</a>

							{isMobile && (
								<img
									className='menu-icon'
									src={TrinityIcon}
									alt='Menu'
									onClick={() =>
										this.setState((state) => ({
											menuIsShown: !state.menuIsShown,
										}))
									}
								/>
							)}
						</div>
					</div>

					{menuIsShown && (
						<div className='header-lower'>
							<nav className='menu-header'>
								<ul className='main-menu'>
									<li className='section-orange' onMouseLeave={!isMobile ? () => this.setState({ openedSubmenu: null }) : null}>
										<div
											className='menu-link main-menu-link'
											onMouseEnter={!isMobile ? () => this.setState({ openedSubmenu: 'orange' }) : null}
											onClick={() =>
												this.setState((state) => ({
													openedSubmenu: state.openedSubmenu !== 'orange' ? 'orange' : null,
												}))
											}
										>
											<img src={'https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Kingdomliving-190x23px.png'} alt='Kingdom Living' />
										</div>
										{openedSubmenu === 'orange' && (
											<ul className='sub-menu'>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-living/our-vision/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Living_Our-vision_Icon.png' alt='Our vision' />
														<main>
															<div>Our vision</div>
															<p>Find out more about the missional heart of the college community and the values that guide everything we do</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-living/community-life/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Living_Community-life_Icon.png' alt='Community life' />
														<main>
															<div>Community life</div>
															<p>See how our vision and values fit into daily life at Trinity as we prepare people to live like the Kingdom is near</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-living/upcoming-events/' className='menu-link sub-menu-link'>
														<img
															src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Living_Upcoming-events_Icon.png'
															alt='Upcoming events'
														/>
														<main>
															<div>Upcoming events</div>
															<p>Get to know us at an open day, dip into Theology at a conference or open lecture, or reconnect at an alumni event</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-living/contact-us/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Living_Contact-us_Icon.png' alt='Contact us' />
														<main>
															<div>Contact us</div>
															<p>Call us, email us, Tweet us, follow us, Like us, send us some post or, even better, come visit us in person</p>
														</main>
													</a>
												</li>
											</ul>
										)}
									</li>
									<li className='section_yellow' onMouseLeave={!isMobile ? () => this.setState({ openedSubmenu: null }) : null}>
										<div
											// href="https://www.trinitycollegebristol.ac.uk/kingdom-learning/"
											className='menu-link main-menu-link'
											onMouseEnter={!isMobile ? () => this.setState({ openedSubmenu: 'yellow' }) : null}
											onClick={() =>
												this.setState((state) => ({
													openedSubmenu: state.openedSubmenu !== 'yellow' ? 'yellow' : null,
												}))
											}
										>
											<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Kingdomlearning-190x23px.png' alt='Kingdom learning' />
										</div>
										{openedSubmenu === 'yellow' && (
											<ul className='sub-menu'>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/theology-programmes/' className='menu-link sub-menu-link'>
														<img
															src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Learning_Theology-programmes_Icon.png'
															alt='Theology programmes'
														/>
														<main>
															<div>Theology programmes</div>
															<p>Study with us and dig deeper into the Bible in a vibrant, challenging, dynamic and Spirit-filled environment</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/faculty-and-staff/' className='menu-link sub-menu-link'>
														<img
															src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Learning_Faculty-and-staff_Icon.png'
															alt='Faculty, staff, and trustees'
														/>
														<main>
															<div>Faculty, staff, and trustees</div>
															<p>
																Get to know some of the people who will teach, challenge, encourage, listen to, support and pray for you during your time at
																Trinity
															</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/study-information/' className='menu-link sub-menu-link'>
														<img
															src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Learning_Study-information_Icon.png'
															alt='Study information'
														/>
														<main>
															<div>Study information</div>
															<p>Get all the info you need about fees, timetables, the library and other practical details of studying in our community</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/apply-now/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Learning_Apply-now_Icon.png' alt='Apply now' />
														<main>
															<div>Apply now</div>
															<p>
																Submit an application today so we can talk further and discern together if Trinity is the right place for you to live, learn and
																lead
															</p>
														</main>
													</a>
												</li>
											</ul>
										)}
									</li>
									<li className='section_red' onMouseLeave={!isMobile ? () => this.setState({ openedSubmenu: null }) : null}>
										<div
											className='menu-link main-menu-link'
											onMouseEnter={!isMobile ? () => this.setState({ openedSubmenu: 'red' }) : null}
											onClick={() =>
												this.setState((state) => ({
													openedSubmenu: state.openedSubmenu !== 'red' ? 'red' : null,
												}))
											}
										>
											<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Kingdomleading-190x23px.png' alt='Kingdom leading' />
										</div>
										{openedSubmenu === 'red' && (
											<ul className='sub-menu'>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-leading/ordination-training/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Ordination_training2.png' alt='Ordination training' />
														<main>
															<div>Ordination training</div>
															<p>Take your next steps toward leadership in the Church of England as part of a Kingdom-focused community</p>
														</main>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-leading/spiritual-formation/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Spiritual_formation.png' alt='Spiritual formation' />
														<main>
															<div>Spiritual formation</div>
															<p>Find out more about the patterns of prayer and worship that are vital in shaping leaders of Christ-like character</p>
														</main>
													</a>
												</li>
												<li>
													<a href='http://www.trinitycollegebristol.ac.uk/blog/' className='menu-link sub-menu-link'>
														<div>Our blog</div>
													</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-leading/our-leaders/' className='menu-link sub-menu-link'>
														<img src='https://www.trinitycollegebristol.ac.uk/wp-content/uploads/2015/01/Alumni.png' alt='Our alumni and friends' />
														<main>
															<div>Our alumni and friends</div>
															<p>Stay connected with Trinity resources and support networks to continue growing your leadership skills post-college</p>
														</main>
													</a>
												</li>
											</ul>
										)}
									</li>
								</ul>
							</nav>
						</div>
					)}
				</div>
				)
				<Switch>
					<Redirect exact from={`/home`} to={`/home/formSubmission`} />
					<Route exact path={`/home/formSubmission`} component={studentFormSubmission} />
					<Route exact path={`/home/formSubmission/studentForm`} render={() => <ApplicationPage isMobile={isMobile} />} />
					<Route exact path={`/home/continue/login`} render={() => <LoginToContinue isMobile={isMobile} />} />
					<Route exact path={`/home/continue/form`} component={() => <ApplicationPage isMobile={isMobile} />} />
				</Switch>
				<footer className='footer'>
					<div className='upper-footer'>
						<a className='item-block' href='https://twitter.com/trinity_bristol'>
							<h2>Follow us on Twitter</h2>
							<div className='icon'>
								<img src={TwitterIcon} alt='' />
							</div>
						</a>
						<a className='item-block' href='https://www.facebook.com/trinitycollegebristol'>
							<h2>Like us on Facebook</h2>
							<div className='icon'>
								<img src={FacebookIcon} alt='' />
							</div>
						</a>

						<a className='item-block' href='https://www.trinitycollegebristol.ac.uk/blog/kingdom-living/view-our-new-prospectus/'>
							<h2>Read our prospectus</h2>
							<div className='icon'>
								<img src={ContactIcon} alt='' />
							</div>
						</a>
						<div className='item-block item-block-long'>
							<h2>Get our latest news, blogs and events direct to your inbox</h2>
							<h4>
								If you would like to receive Trinity's e-newsletter four times a year, please email
								<a href='mailto:m.stratis@trinitycollegebristol.ac.uk?subject=Newsletter sign-up'> m.stratis@trinitycollegebristol.ac.uk </a>
								with your full name, and we are happy to add you to that mailing list.
							</h4>
						</div>
					</div>
					<div className='lower-footer'>
						<div className='footer-left'>
							<nav>
								<div className='main-list'>
									<div style={{ display: 'flex' }}>
										<div>
											<div className='title'>Study with us</div>
											<ul className='sub-list'>
												<li>
													<a href='http://www.trinitycollegebristol.ac.uk/wp-content/uploads/2019/05/TRI-Prospectus-2019-SCREEN.pdf'>Our prospectus</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-leading/ordination-training/'>Ordination</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/theology-programmes/part-time-study/'>Part-time</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/study-information/'>Internationals</a>
												</li>
											</ul>
										</div>
										<div>
											<div className='title'>Quicklinks</div>
											<ul className='sub-list'>
												<li>
													<a href='https://www.justgiving.com/trinity-bristol'>Giving to Trinity</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/study-information/fees/'>Our fees</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/study-information/'>The library</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/study-information/'>Learning support</a>
												</li>
											</ul>
										</div>
									</div>

									<div style={{ display: 'flex' }}>
										<div>
											<div className='title'>Get in touch</div>
											<ul className='sub-list'>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-learning/apply-now/'>Apply now</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/kingdom-living/contact-us/'>Contact or find us</a>
												</li>
												<li>
													<a href='mailto:info@trinitycollegebristol.ac.uk'>Email us</a>
												</li>
												<li>
													<a href='https://www.trinitycollegebristol.ac.uk/job-vacancies/'>Job vacancies</a>
												</li>
											</ul>
										</div>
										<div>
											<div className='title'>Trinity sites</div>
											<ul className='sub-list'>
												<li>
													<a href='http://www.muddybootsdaynursery.co.uk'>Day Nursery</a>
												</li>
												<li>
													<a href='http://www.trinitycollegebristol.ac.uk/hiretrinity'>Hire Trinity</a>
												</li>
												<li>
													<a target='_blank' href='http://moodle.bristoltheological.ac.uk'>
														Moodle
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</nav>
						</div>
						<div className='footer-right'>
							<div className='logo'>
								<img style={{ height: 100 }} src={DSAImage} alt='DSA graphic' />
								<a href='http://www.qaa.ac.uk/reviews-and-reports/provider?UKPRN=10023464' target='_blank'>
									<img src={ReviewGraphic} alt='QAA Review Graphic thumbnail' />
								</a>
								<a href='https://www.abdn.ac.uk/' target='_blank'>
									<img src={UniAberdeen} alt='' />
								</a>
								<a href='https://www.dur.ac.uk/' target='_blank'>
									<img src={UniDurham} alt='' />
								</a>
							</div>
						</div>
					</div>
					<p className='footer-text'>
						Trinity College (Bristol) Ltd. is a company registered in England no. 1056656, registered charity no. 311793 and our registered office is Stoke
						Hill, Bristol, BS9 1JP
					</p>
				</footer>
			</div>
		)
	}
}

export default withRouter(homePage)
