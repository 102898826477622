import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { CreateApplication } from './Create'
import { EditApplication } from './Edit'
import * as API from '@Root/API'

export const ApplicationPage = ({ isMobile }) => {
	const location = useLocation()
	const pathname = location.pathname
	const isNew = pathname.includes('formSubmission')

	const [options, setOptions] = useState({ genders: [], nationalities: [], countries: [], programmes: [] })

	useEffect(() => {
		API.getOptions().then(({ data }) => {
			const { genders, nationality_types, countries, programmes, ethnicities } = data
			setOptions({
				genders: genders.map(({ id, label }) => ({ value: id, label })),
				nationalities: nationality_types.map(({ id, label }) => ({ value: id, label })),
				countries: countries.map(({ id, name }) => ({ value: id, label: name })),
				ethnicities: ethnicities.map(({ id, label, code }) => ({ value: id, label: `${code} - ${label}` })),
				programmes,
			})
		})
	}, [])

	return <div>{isNew ? <CreateApplication options={options} isMobile={isMobile} /> : <EditApplication options={options} isMobile={isMobile} />}</div>
}
