import axios from './axios'

// AUTH

export const countriesListRequest = () => {
	return axios.get('get-country-for-student-application')
}

export const ethnicitiesRequest = () => {
	return axios.get('get-ethnicities-list')
}

export const dioceseRequest = (page = 1, limit = 50, name = '', id) => {
	return axios.get(`contacts-type-dioceses`, { params: { sortBy: 'name:asc', page, limit, search: `${name ? `name:${name}` : ''}${id ? `;id:${id}` : ''}` } })
}

export const saveStudenFormRequest = (data) => {
	return axios.post('create/student-application', data)
}

export const finishStudenFormRequest = (data) => {
	return axios.post('create/finish-student-application', data)
}

export const getFormViaForm = (mobile_phone, date_of_birth, email, token) => {
	return axios.get(`get-form-student-application-via-form?mobile_phone=${mobile_phone}&date_of_birth=${date_of_birth}&email=${email}&token=${token}`)
}

export const updateSavedForm = (data) => {
	return axios.put('update/student-application', data)
}

export const finishSavedForm = (data) => {
	return axios.put('update/finish-student-application', data)
}

export const getFormViaToken = (token) => {
	return axios.get(`get-form-student-application-via-token?token=${token}`)
}

export const updateToken = (data) => {
	return axios.put('update/token/student-application', data)
}

export const getProgrammes = () => {
	return axios.get('get-available-programs')
}

export const getFormSubmissionContent = () => {
	return axios.get('content/application-form')
}

export const getOptions = () => {
	return axios.get('get-data-for-create-application-form')
}
