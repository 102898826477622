export const collegesConfig = () => {
	const hostname = window.location.hostname
	const collegeAbbreviation = hostname.split('.')[1]
	const colleges = {
		trinity: 'Trinity',
		stmellitus: 'St Mellitus College Trust',
		ctf: 'CTF',
	}
	return colleges[collegeAbbreviation]
}
