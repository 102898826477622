import React, { Component } from "react";
import  "./popUp.scss"

class Popup extends Component {
    render() {
        return (
            <div className='popup'>
                <div className='popup_inner'>
                    <span style={{width: this.props.txtWidth}} className={"massage"}>{this.props.text}</span>
                    <div className={"btn"}>
                        <button onClick={this.props.closePopup}>OK</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup