import React from 'react'
import { ApplicationForm } from '@Root/Components'
import * as API from '@Root/API'
import { useLocation } from 'react-router'

export const EditApplication = ({ options, isMobile }) => {
	const location = useLocation()
	const data = location.state.form
	const token = location.state.token

	const onSubmit = (payload) => {
		return API.finishSavedForm(payload)
	}

	const onSave = (payload) => {
		return API.updateSavedForm(payload)
	}

	return <ApplicationForm onSubmit={onSubmit} onSave={onSave} options={options} initialValue={data} token={token} isMobile={isMobile} />
}
