import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { educationQualificationOptions, professionalQualificationsOptions, recentEmploymentOptions, booleanOptions } from '@Root/configs'

export const EducationAndEmployment = ({ onChange, data, onAddRow, errors = {}, onChangeField }) => {
	const { educational_qualifications = [], recent_employment_history = [], professional_qualifications = [], criminal_record } = data
	return (
		<div className={styles.section}>
			<SectionTitle>4. Education and Employment</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Educational qualifications:'}
					options={educationQualificationOptions}
					field={'formTable'}
					rows={educational_qualifications}
					onAddRow={onAddRow('educational_qualifications')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('educational_qualifications')}
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'Professional qualifications:'}
					options={professionalQualificationsOptions}
					field={'formTable'}
					rows={professional_qualifications}
					onAddRow={onAddRow('professional_qualifications')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('professional_qualifications')}
					fieldClass={'single'}
				/>
				<Field
					label={'Recent employment history:'}
					options={recentEmploymentOptions}
					field={'formTable'}
					rows={recent_employment_history}
					onAddRow={onAddRow('recent_employment_history')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('recent_employment_history')}
					fieldClass={'single'}
				/>
				<p>
					NB: <span style={{ color: '#9B9B9B' }}>Please be prepared to provide copies of any certificates for academic and professional qualifications</span>
				</p>
				<div>
					<Field
						label={'Criminal record'}
						options={booleanOptions}
						field={'radioInput'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
						value={criminal_record ? 1 : 2}
						onChange={(value) => onChangeField('criminal_record')(value === 1)}
						isInline
						error={errors.criminal_record}
						maxLength={100}
						fieldClass={'single'}
					/>
					<p>
						NB: <span style={{ color: '#9B9B9B' }}>Answering “yes” will not necessarily jeopardise your application</span>
					</p>
				</div>
			</div>
		</div>
	)
}
